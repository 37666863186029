jQuery(document).ready(function () {
  jQuery('#btn-profile').click(function() {
    jQuery(this).toggleClass('cross');
    jQuery('.profile-description').toggleClass('show');
  });
  
  jQuery('#btn-bio').click(function(event) {
    event.preventDefault();
    jQuery(this).toggleClass('active');
    jQuery('.plain-profile').toggleClass('show');
  });

  jQuery('#theCarousel').slick();
  
  jQuery('#btn-share').click(function(event) {
    event.preventDefault();
    jQuery(this).find('span').addClass('active');
    copyURL();
    setTimeout(function(){ jQuery('#btn-share').find('span').removeClass('active'); }, 1000);
  });

  function copyURL() {
    var placeHolder = document.createElement('input'),
        text = window.location.href;
    document.body.appendChild(placeHolder);
    placeHolder.value = text;
    placeHolder.select();
    document.execCommand('copy');
    document.body.removeChild(placeHolder);
  }


});
